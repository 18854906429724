
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import Sale from "./Pages/Sale/Sale";
import SaleDetails from "./Pages/SaleDetails/SaleDetails";
import Ref from "./Pages/RefDetails/RefDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Sale />} />
          <Route path="saledetails" element={<SaleDetails />} />
          <Route path="referdetails" element={<Ref />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
