import React, { useCallback, useEffect, useState } from "react"
import './Projects.css'
import profilePic from '../../Assets/images/profilePic.png'
import Round from "../../Assets/Abi/OcRound.json"
import { localeFixed } from "../../config/helpers"
import Countdown from "react-countdown";
import { ethers } from "ethers";
import { defaultWeb3 } from "../../config"
import { Link } from "react-router-dom"
// url5
export const RoundPage = (props) => {


    // url testing
    // testing();

    // console.log("main url", mainurl);
    const [Tocsold, setocsold] = useState(0)
    const [isLive, setLive] = useState(false)
    const [isFull, setIsFull] = useState(false)

    const [totalOCSold, settotalOCSold] = useState(0)
    const [roundOCLimit, setOcLimit] = useState(0)


    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))




    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })
    // console.log("wallet address",walletAddress);

  // Check Claim status of user 
    const [ClaimStatus, setClaimStatus] = useState(false)
    
    async function checkOcBoughtStatus() {
        if(walletAddress){
        let signer
   
        const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
        signer = metaMaskProvider.getSigner();
       
        const roundInstance = new ethers.Contract(props.address, Round, signer)
        const userData = await roundInstance.userData(walletAddress)
        const totalOCAllotted = ethers.utils.formatEther(userData.totalOCBought._hex)

        let userAmount = await roundInstance.ocBought(walletAddress)
        userAmount = ethers.utils.formatEther(userAmount)

        // console.log(userAmount, "userAmount");
        const checkUser = (Number(userAmount) === 0)
        // console.log(checkUser);
    

        if (checkUser === false) {

            if(Number(totalOCAllotted) > 0){

                setClaimStatus("Claim Pending")
            }

        } else {

            if(Number(totalOCAllotted) !== 0){
                setClaimStatus("Claimed")
            }

            // console.log("Not allowed");
        }
    }
    else{
        // status will not display when user wallet disconnect
        setClaimStatus(false)
    }
    }


    async function dataForRound() {
        // console.log("start");

        //signer condition start 
        let provider = defaultWeb3
        let signer
        if (window.ethereum) {
            try {
                // console.log("enter");
                const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                const accounts = await metaMaskProvider.listAccounts();
                if (accounts.length) {
                    // console.log("condittion match");
                    provider = metaMaskProvider;
                    signer = metaMaskProvider.getSigner();
                }
                else {
                    // console.log("else call");

                    signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                }
            } catch (exception) {
                console.error('Unable to list accounts', exception);
            }
        }
        else {
            // console.log("else call");

            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
        }
        // console.log(signer, "after");
        // signer condition closed

        try {

            const roundInstance = new ethers.Contract(props.address, Round, signer)

            // console.log(roundInstance,"roundintance");
            // console.log("address",props.address);

            let _roundOCLimit = await roundInstance.roundLimit()



            _roundOCLimit = ethers.utils.formatEther(_roundOCLimit)
            setOcLimit(parseFloat(_roundOCLimit))
            // console.log("roundOCLimit",_roundOCLimit);
            // console.log(roundOCLimit);

            let ocsold = await roundInstance.ocSold()
            ocsold = ethers.utils.formatEther(ocsold)
            // console.log(ocsold, "hello");

            if ((parseFloat(ocsold) + 200) >= parseFloat(_roundOCLimit)) {
                setIsFull(true)
            }
            settotalOCSold(ocsold)
            // console.log("ocsold", ocsold);


            setocsold(parseFloat(roundOCLimit) !== 0 ? (ocsold * 100 / roundOCLimit) : 0)
        } catch (Err) {
            // console.log("err", Err);

        }
    }

    let fullRounds = [
        "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162",
        "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36",
        "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e",
        "0x37354c01db60d34f058c87799Dfa4140f5222194",
        "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c",
        "0xB24b0A062516aFa2ae4c670488782d65B4C016CD"

    ]

    useEffect(() => {
        dataForRound()
        checkOcBoughtStatus()
    }, [])

    useEffect(() => {
        checkOcBoughtStatus()
     
       }, [walletAddress])

    useEffect(() => {
        dataForRound()
        // testing()
    }, [isLive])
    useEffect(() => {
        const intervalId = setInterval(() => {
  checkOcBoughtStatus()
            dataForRound()

        }, 5000)

        return () => clearInterval(intervalId); //This is important
    }, []);

    const isFullIncluded = useCallback((check) => {
        let r = fullRounds.filter((ele) => ele.toLowerCase() === check.toLowerCase())
        return r.length != 0 ? true : false
    }, [fullRounds])

    return (
        <div className="col-md-6 col-lg-4">
            <div className={`listBoxInner ${!isFullIncluded(props.address) && (props.startTime <= Date.now()) && (Math.round(totalOCSold * 100 / roundOCLimit) !== 100) && 'active'} `}>
                <div className="row profileInfo mb-3">
                    <img className="profilePic" src={profilePic} alt="" width="56" height="56" />
                    <ul>
                        <li className={`upcoming ${!isFullIncluded(props.address) && (props.startTime > Date.now()) && "active"}`}><span></span>Upcoming</li>
                        <li className={`saleLive ${!isFullIncluded(props.address) && (props.startTime <= Date.now()) && (Math.round(totalOCSold * 100 / roundOCLimit) !== 100) && 'active'} `}><span></span>Sale Live</li>
                        <li className={`saleEnded ${(isFullIncluded(props.address) || (Math.round(totalOCSold * 100 / roundOCLimit) === 100)) && 'active'}`}><span></span>Sale Ended</li>
                        <br/>
                        <br/>
                        
                        <li className={`upcoming ${ClaimStatus ? "active" : false}`}><span></span>{ClaimStatus}</li>
                    </ul>
                </div>
                <div className="infoBox">
                    <h2>Round {props.idx}</h2>
                    <p className="subtitle">Fair Launch</p>
                    <h3 className="mt-3">Hardcap</h3>
                    <strong>{props.address.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '50,000' : (props.address.toLowerCase() === "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36".toLowerCase() ? "100,000" : props.address.toLowerCase() === "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e".toLowerCase() ? "200,000" : (props.address.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? "500,000" : props.address.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? "1,000,000" : props.address.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() ? "2,000,000" : localeFixed(roundOCLimit) || 0))} OC</strong>
                    <p className="progressBar">Progress {isFullIncluded(props.address) ? 100 : Math.round(totalOCSold * 100 / roundOCLimit || 0)}%</p>
                    <div className="progresLine"><span style={{ width: `${isFullIncluded(props.address) ? '100' : totalOCSold * 100 / roundOCLimit}%` }}></span></div>
                    <div className="row tableBox">
                        <p className="ttl">0 OC <span>{props.address.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '50,000' : (props.address.toLowerCase() === "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36".toLowerCase() ? "100,000" : props.address.toLowerCase() === "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e".toLowerCase() ? "200,000" : (props.address.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? "500,000" : localeFixed(props.ocAllotted) || 0))} OC</span></p>
                        <p> OC Price: <span> ${props.price}</span></p>
                        <p>OC Sold: <span>{props.address.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '50,000' : (props.address.toLowerCase() === "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36".toLowerCase() ? "1,00,000" : props.address.toLowerCase() === "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e".toLowerCase() ? "2,00,000" : (props.address.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? "5,00,000" : props.address.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? "1,000,000" : props.address.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() ? "2,000,000" :0))}</span></p>
                    </div>
                    <div className="dividerLine"></div>
                    <div className="row saleTineView">

                        <p className="timesSale"> {(isFullIncluded(props.address) || Math.round(totalOCSold * 100 / roundOCLimit) === 100) ? "Sale is Full" : (props.startTime <= Date.now() ? "Sale Ongoing" : "Sale Starts In:")}
                            <br />
                            {(isFullIncluded(props.address)) || (props.startTime < Date.now()) || <Countdown date={props.startTime} onComplete={() => {
                                setLive(true)
                            }} />}
                        </p>
                        {
                            isFullIncluded(props.address) && <Link to={`/saledetails?id=${props.address}`} className="btnView">View</Link>
                        }
                        {/* {
                            (props.address.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() && props.startTime <= Date.now()) && <Link to={`/saledetails?id=${props.address}`} className="btnView">View</Link>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}