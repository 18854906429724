import React, { useState } from 'react'
import linetoprightbox from '../../Assets/images/linetoprightbox.svg'
import linebottomrightbox from '../../Assets/images/linebottomrightbox.svg'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Projects from '../../Components/Projects/Projects'
import Faqs from '../../Components/Faqs/Faqs'
import SaleDetails from '../../Components/SaleDetailList/SaleDetailList'

const Sale = () => {
    
    // const pull_data = (data) => {
    //   return data
    //   }
    //func={pull_data}
    return (
        <>
            <section className='borderboxmain'>
                <img className='linetopleft' src={linetoprightbox} alt="border line" />
                <img className='linebottomright' src={linebottomrightbox} alt="border line" />
            </section>
            <PublicSale  />  
            <SaleDetails />
            <Projects />
            <Faqs />
        </>
    )
}

export default Sale