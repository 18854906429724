import React, { useState } from 'react'
import './PublicSale.css'
import OCAnimOrig from '../../Assets/images/OC-Change.gif'
import OCAnimPlace from '../../Assets/images/backgif.gif'
import ScrollAnimation from 'react-animate-on-scroll';
import ProgressiveImage from "react-progressive-graceful-image";
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import copyIcon from '../../Assets/images/copyicon.png'
import { Link } from 'react-router-dom';



const PublicSale = () => {
  
    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))

    const notify = () => toast.success('Copied Successfully!')
    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })
   

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <section className="publicSale">
                <div className="oc_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="infobox">
                                <ScrollAnimation animateIn="flipInX"
                                    initiallyVisible={true}
                                    animateOnce={true}>
                                    <h1>Get early access to the ideas of OC</h1>
                                    <p className="mt-4 mb-0">Highly-vetted Web3 projects you can trust. Supported by industry-leading creators and funds.</p>
                                  
                                    {walletAddress &&
                                    <>
                                        <h3 className='mt-4'>Copy Referral Link</h3>
                                        <div className="buttonGrp">
                                            <CopyToClipboard text={`https://sale.oxchange.finance/?refferalCode=${walletAddress}`}>
                                                <button onClick={notify} className="oc_btn copyRefferalCode"><span>{`https://sale.oxchange.finance/?refferalCode=${walletAddress.substring(0, 3)} ... ${walletAddress.slice(-3)}`}</span> <img src={copyIcon} alt="Copy icon" /></button>
                                            </CopyToClipboard>
                                            <Link to="/referdetails"> <button className="oc_btn">Referral Activity Details</button></Link>
                                            {/* <button className="oc_btn">Show Detail</button> */}
                                        </div>
                                        </>
                                    }
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="imageBox">
                                {/* <img src={OCAnim} alt="OX Change" width="500" height="500" /> */}
                                <ProgressiveImage delay={0} src={OCAnimOrig} placeholder={OCAnimPlace}>
                                    {(src) => <img src={src} alt="OX Change" />}
                                </ProgressiveImage>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PublicSale