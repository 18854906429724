import React, { useEffect, useState } from 'react'
import './SaleDetailList.css'
import Web3 from 'web3'
import { Contract, ethers } from "ethers";
import Web3Modal from "web3modal";
import saleAbi from "../../Assets/Abi/OcSale.json"
import { rounds } from '../../config/rounds';

const SaleDetailList = () => {


    let oracleInstance;
    const oracleAddr = "0x6a23E9F01fB1eb1D4009F7a8419F6d92d495b69D"
    let walletAddress = localStorage.getItem('walletaddress');
    const tokenAddresses = '0x2fed1399b39eedb16837129bb7bf0a0f35d7aaf7'





    return (
        <>
            <section className="saleDetails">
                <div className="oc_container">
                    <div className="title">
                        <h2>Sale Details</h2>
                    </div>
                    <div className="saleDetailsList">
                        <div className='table-responsive'>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Round</th>
                                        <th>Date</th>
                                        <th>OC Quantity</th>
                                        <th>Price  $</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        rounds.map((value, idx) =>
                                            <tr>
                                                <td>{idx + 1}.</td>
                                                <td>{value.startDate}</td>
                                                <td>{value.ocAllotted.toLocaleString()}</td>
                                                <td>$ {value.price}</td>
                                                <td>{idx == 5 ? "48 Hours"  : "24 hours"}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {/* Show Details */}

        </>

    )
}

export default SaleDetailList