import React, { useEffect, useState } from 'react'
import './Statisics.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import saleAbi from "../../Assets/Abi/OcSale.json"
import Round from "../../Assets/Abi/OcRound.json"
import Web3 from 'web3'
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { defaultWeb3, oracleAddress } from '../../config';
import { rounds } from '../../config/rounds';
import { localeFixed } from '../../config/helpers';
let web3 = defaultWeb3

const Statisics = () => {
    // const copyReferralLink = () => toast("Copied Successfully!");

    const copyReferralLink = () => toast.success('Copied Successfully!', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let roundAddr = String(queryParameters.get("id"))
    const [SaleSupply, setSaleSupply] = useState(0)
    const [OCPrice, setOCPrice] = useState(0)
    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))

    const notify = () => toast.success('Copied Successfully!')
    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })

    const [ocsold, setocsold] = useState(0)
    const [maticRaised, setmaticRaised] = useState(0)
    const [usdtRaised, setusdtRaised] = useState(0)
    const [USDraised, setUSDraised] = useState(0)
   

    const [roundOCLimit, setroundOCLimit] = useState()
    let oracleInstance;
    const oracleAddr = oracleAddress

   


    async function dataForRound() {
         //signer condition start 
         let provider = defaultWeb3
         let signer
         if (window.ethereum) {
             try {
                 // console.log("enter");
                 const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                 const accounts = await metaMaskProvider.listAccounts();
                 if (accounts.length) {
                     // console.log("condittion match");
                     provider = metaMaskProvider;
                     signer = metaMaskProvider.getSigner();
                 }
                 else {
                     // console.log("else call");
 
                     signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                 }
             } catch (exception) {
                 console.error('Unable to list accounts', exception);
             }
         }
         else {
             // console.log("else call");
 
             signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
         }
         // console.log(signer, "after");
         // signer condition closed
        // if ( !signer._address )  {
        //     signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
        // }
        const roundInstance = new ethers.Contract(roundAddr, Round, signer)
try {
    let roundOCLimit = await roundInstance.roundLimit()

        roundOCLimit = ethers.utils.formatEther(roundOCLimit)
        setroundOCLimit(roundOCLimit)
        try {
            let totalUSDRaised =  await  roundInstance.totalUSDRaised()
            totalUSDRaised = ethers.utils.formatEther(totalUSDRaised)

            setUSDraised(totalUSDRaised)
           
        } catch (error) {
            // console.log(error);
        }
      
       
        // console.log("limit", roundOCLimit);
        let totalRaised = await roundInstance.totalRaised()
        let totalmaticRaised = ethers.utils.formatEther(totalRaised[1])
        setmaticRaised(totalmaticRaised)
        let totalusdtRaised = ethers.utils.formatEther(totalRaised[0])
        setusdtRaised(totalusdtRaised)
        
        // console.log("MATIC ", ethers.utils.formatEther(totalRaised[1]))
        // console.log("USDT", ethers.utils.formatEther(totalRaised[0]))
        let ocsold = await roundInstance.ocSold()
        ocsold = ethers.utils.formatEther(ocsold)
        setocsold(ocsold)
        // console.log("ocsold", ocsold);
} catch (error) {
    // console.warn(error);
}
        




    }


    function roundDetails() {
        let filterd = rounds.filter((value) => value.address === roundAddr)


        setSaleSupply(localeFixed(filterd[0].ocAllotted))
        setOCPrice(filterd[0].price)
    }

    useEffect(() => {
        roundDetails()
        dataForRound()
    }, [])
    useEffect(() => {
        const intervalId = setInterval(() => {
            dataForRound()
            roundDetails()
        }, 5000)

        return () => clearInterval(intervalId); //This is important
    },);
    return (
        <>
            <section className="statisics">
                <div className="oc_container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="boxinner leftinfo">
                                <h2>STATISTICS</h2>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>21,000,000</h3>
                                            <p>Total Supply</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>{SaleSupply}</h3>
                                            <p>Sale Supply</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>You can after sale ends</h3>
                                            <p>Vesting Period</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>5%</h3>
                                            <p>Referral</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        {walletAddress &&
                                            <div className="box">
                                                <strong>Copy Referral Link</strong>
                                                <CopyToClipboard text={`https://sale.oxchange.finance/?refferalCode=${walletAddress}`}>
                                                    <h5 className='copyReferralLink' onClick={notify}>{`https://sale.oxchange.finance/?refferalCode=${walletAddress.substring(0, 3)} ... ${walletAddress.slice(-3)}`}</h5>
                                                </CopyToClipboard>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="boxinner rightinfo">
                                <h2>SALE STATISTICS</h2>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>{OCPrice}</h3>
                                            <p>OC Price</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>${roundAddr.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '20,000' :roundAddr.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? '230000':roundAddr.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? '480,000' :roundAddr.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() ? '1,000,000' : (localeFixed(USDraised) || 0)}</h3>
                                            <p>Total Raised</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>{roundAddr.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '17,241.38' : roundAddr.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? '205789.03' :roundAddr.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? '428,571.42' : (localeFixed(maticRaised) || 0)}</h3>
                                            <p>Total Matic</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="box">
                                            <h3>{usdtRaised * 1000000000000 || 0}</h3>
                                            <p>Total USDT</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="box">
                                            <strong>{roundAddr.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '50,000' :roundAddr.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? '500,000' :roundAddr.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? '1,000,000' :roundAddr.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() ? '1,000,000' : (localeFixed(ocsold) || 0)}</strong>
                                            <h5>Total OC Sold</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default Statisics