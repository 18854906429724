import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import logo from '../../Assets/images/oc-change-logo.png'
import Hamburgericon from '../../Assets/images/hamburger.svg'
import './header.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { FaSignOutAlt } from "react-icons/fa";
import Web3 from 'web3'
import { Contract, ethers } from "ethers";
import Web3Modal from "web3modal";
import saleAbi from "../../Assets/Abi/OcSale.json"
import { ocToken, usdtToken } from '../../config'
import { localeFixed } from '../../config/helpers'

const providerOptions = {};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

const notify = () => toast.success('Copied Successfully!')
const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  
//referral code setting in local storage
  const  refferalCode = String(queryParameters.get("refferalCode")) 

  if (refferalCode !== "null") {
    localStorage.setItem("refcode",refferalCode)
  }

 
let walletAddress =  window.localStorage.getItem('walletaddress');

  useEffect(()=>{
     walletAddress = window.localStorage.getItem('walletaddress');
    window.dispatchEvent(new Event("storage"));
  },[])
  
  const [accounts, setAccounts] = useState(walletAddress)
  const [ether, setEther] = useState(0)
  const [oc, setOc] = useState()
  const  ocBal =  localStorage.getItem("oc")
  const [Refurl, setRefUrl] = useState()

  let chainId = 137
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }
  const tokenABI = [{
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "type": "function"
  }]
  const tokenAddresses = ocToken
  const maticAddress = "0x0000000000000000000000000000000000001010"
  const usdtAddress = usdtToken

  // SWAP Network or add poly mainnet network 
  async function addTestnetToMetamask() {
   
    try {
      // check if the chain to connect to is installed
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x89' }], // chainId must be in hexadecimal numbers
    });
  
  } catch (error) {
   
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x89',
              chainName: "Polygon Chain",
              rpcUrls: ['https://polygon-rpc.com'],
              nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC", // 2-6 characters long
                  decimals: 18
              },
            },
          ],
        });
        // alert("Chain Added!")
      } catch (addError) {
          // alert(JSON.stringify(addError));
        console.error(addError);
      }
    // }
    // alert(JSON.stringify(error));
    console.error(error);
  }
  }
  async function doConnectProfile() {
    if (window.ethereum) {
  
      try {
      
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        provider.send("eth_requestAccounts", []).then((accounts) => {
          if (parseInt(window.ethereum.chainId) !== chainId) {
            addTestnetToMetamask()
          }
          setAccounts(accounts[0])
          window.localStorage.setItem('walletaddress', accounts[0]);
          window.dispatchEvent(new Event("storage"));
  
          onClickConnect()

        })
      
       

      } catch (error) {
        return {
          connectedStatus: false,
          status: "🦊 Connect to Metamask using the button on the top right."
        }
      }
    }
  }
 
  async function onClickConnect() {
   
 if (accounts === null || accounts === undefined) {

  //do nothing​
//  console.log("hello");

  }
  else{
  
       const provider = new ethers.providers.Web3Provider(window.ethereum)
      //  provider.send("eth_requestAccounts", [])
     
      //  const signer = provider.getSigner()
   
      //  let ether;
      //  ether = await signer.getBalance()
       
   
      //  ether = ethers.utils.formatEther(ether, 18)
      //  setEther(ether)
      //  const walletAddress = await signer.getAddress()
      //  setAccounts(walletAddress)
      //  console.log(walletAddress);
   
     
   
   
       try {
         let token;
         const ocTokenContract = await new ethers.Contract(tokenAddresses, tokenABI, provider);
   
         token = await ocTokenContract.balanceOf(accounts)
         token = ethers.utils.formatEther(token, 18)
      
   
         //matic balance
         const maticTokenContract = await new ethers.Contract(maticAddress, tokenABI, provider);
         let matictoken = await maticTokenContract.balanceOf(accounts)
         matictoken = ethers.utils.formatEther(matictoken, 18)
   
         
         
   
   
         //usdt balance
         const usdtTokenContract = await new ethers.Contract(usdtAddress, tokenABI, provider);
         let usdttoken = await usdtTokenContract.balanceOf(accounts)
         
   
         usdttoken = ethers.utils.formatEther(usdttoken)
   
        
         localStorage.setItem("matic", matictoken)
         localStorage.setItem("usdt", usdttoken * 1000000000000)
         localStorage.setItem("oc",token)
         
   
         setRefUrl(`http://sale.oxchange.finance?refferalCode=${accounts}`)
       
        
   
   
         setOc(token)
       } catch (Err) {
         // console.log("err", Err);
       }
   
   }
  
  }

  async function disconnectwallet() {
   
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
    setAccounts()
    setOc()
  }
 useEffect(() => {
        const intervalId = setInterval(() => {
        
         onClickConnect()
        
        }, 1000)
        return () => clearInterval(intervalId); //This is important
      },);

  return (
    <>
      <nav className="navbar">
        <div className="navbar_inner oc_row space-between align-items-center">
          <div className="logo">
          <a href="https://oxchange.finance" target="_blank">
            <img src={logo} alt="logo" width="140" height="140" />
            </a>
          </div>
          {/* <div className="menu-icon" onClick={handleShowNavbar}>
            <img src={Hamburgericon} alt="Hamburger" width="30" height="25" />
          </div> */}
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
            {/* <ul>
              <li>
                <NavLink to="/">Buy OC</NavLink>
              </li>
              <li>
                <NavLink to="/">Statistics</NavLink>
              </li>
              <li>
                <NavLink to="/">How to Buy</NavLink>
              </li>
              <li>
                <NavLink to="/">Whitepaper</NavLink>
              </li>
            </ul> */}
          </div>
          <div className="balancebox">
            <ul>
              {accounts && <>
                <li><strong>{localeFixed(ocBal)}</strong>OC</li>
                <li onClick={notify}>
                  <CopyToClipboard text={accounts}>
                    <span> {accounts && <> {accounts.substring(0, 3)} ... {accounts.slice(-3)}</>}</span>
                  </CopyToClipboard>
                </li>
              </>
              }
              {accounts == undefined &&
                <li>
                  <span onClick={doConnectProfile}>Connect Wallet </span>
                </li> || <li> <span className='logoutSn' onClick={disconnectwallet}>Disconnect Wallet<FaSignOutAlt style={{ marginLeft: "3px" }} />  </span></li>
              }
            </ul>
          </div>
        </div>
      </nav>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
    </>
  )
}

export default Header