import Web3 from "web3"

export const oracleAddress = "0x6a23E9F01fB1eb1D4009F7a8419F6d92d495b69D"

export const ocToken = "0x6032e93dDC9Ef65212A9bDe047A93e5d16126C8e"
export const usdtToken = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"

export const rpcUrl = "https://hardworking-maximum-replica.matic.quiknode.pro/e48e078c24af6662e28177f534ea3a0c3dbe1622/"

export const defaultWeb3 = new Web3(rpcUrl)





 export const url1 =  new Web3("https://fittest-greatest-scion.matic.quiknode.pro/f4e322fdf2c993c987fb3eea65614bb308f3e261/")

//  export const url2 =  new Web3("https://wider-autumn-firefly.matic-testnet.quiknode.pro/fb1b5165ae1143ac3bd6b51aabe24956e634ccdb/")

 export const url3 =  new Web3("https://alien-small-general.matic.quiknode.pro/1101fea5abc35552b6f2a55b9f3545d47c9d0e84/")

 export const url4 =  new Web3("https://hardworking-maximum-replica.matic.quiknode.pro/e48e078c24af6662e28177f534ea3a0c3dbe1622/")

 export const url5 =  new Web3("https://solitary-sleek-thunder.matic.quiknode.pro/3b2bcf11cf72036fd1d3c4f959e459186f97f34c/")

 export const url6 =  new Web3("https://stylish-convincing-feather.matic.quiknode.pro/24944e4b6f82a96c321eaed36b6e2aa6c796c766/")

 export const url7 = new Web3("https://powerful-twilight-dinghy.matic.quiknode.pro/fcd0a794897c1637341f6c2009aece381d614d2b/")

 export const url8 =  new Web3("https://thrumming-solemn-putty.matic.quiknode.pro/fdacf9a94171104088d174bfe3e3769e952e0b6f/")