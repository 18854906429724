export const rounds = [
    {
        address: "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162",
        price: 0.40,
        startDate: "20 March 2023",
        ocAllotted: 50000,
        startTime: 1679304599*1000
    },
    {
        address: "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36",
        price: 0.42,
        startDate: "21 March 2023",
        ocAllotted: 100000,
        startTime: 1679390999*1000
    },
    {
        address: "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e",
        price: 0.44,
        startDate: "22 March 2023",
        ocAllotted: 200000,
        startTime: 1679477399*1000
    },
    {
        address: "0x37354c01db60d34f058c87799Dfa4140f5222194",
        price: 0.46,
        startDate: "23 March 2023",
        ocAllotted: 500000,
        startTime: 1679563799*1000
    },
    {
        address: "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c",
        price: 0.48,
        startDate: "24 March 2023",
        ocAllotted: 1000000,
        startTime: 1679650199*1000
    },
    {
        address: "0xB24b0A062516aFa2ae4c670488782d65B4C016CD",
        price: 0.50,
        startDate: "25 March 2023",
        ocAllotted: 2000000,
        startTime: 1679736599*1000
    },
]