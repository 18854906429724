import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import './RefDetails.css'
import { Contract, ethers } from "ethers";
import Web3Modal from "web3modal";
import saleAbi from "../../Assets/Abi/OcSale.json"
import { oracleAddress, rpcUrl } from '../../config';
import linetoprightbox from '../../Assets/images/linetoprightbox.svg'
import linebottomrightbox from '../../Assets/images/linebottomrightbox.svg'
import Tooltip from "react-simple-tooltip"
import { css } from "styled-components"
import toast, { Toaster } from 'react-hot-toast';
import { localeFixed } from '../../config/helpers';
import Countdown from 'react-countdown';



const Ref = () => {


    let oracleInstance;
    const oracleAddr = oracleAddress
    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))

    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');

        setwalletAddress(walletAddress)





    })



    const [referData, setreferData] = useState()
    const [totalReferredAmount, setTotalReferredAmount] = useState()
    const [noOfRefrral, setNoOfRefrral] = useState()
    const [referralclaimed, setReferralclaimed] = useState()
    const [Timer, setTimer] = useState(0)
    const [TimeStatus, setTimeStatus] = useState(false)

    //  Referral Details of users 
    async function referralGeneration() {
        if (walletAddress) {

            let array = []
            // console.log("fun call");
            // console.log(walletAddress);

            const signer = window.ethereum ? (new ethers.providers.Web3Provider(window.ethereum)).getSigner() : new ethers.providers.JsonRpcProvider(rpcUrl)

            oracleInstance = new ethers.Contract(oracleAddr, saleAbi, signer)


            // referral timer
            let timer = await oracleInstance.lastReferClaimed(walletAddress)

            timer = parseInt(timer)

            if (Number(timer) > 0) {
                var date = new Date(timer * 1000); // Last claim date
                date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
                // console.log(date);
                setTimer(Date.parse(date))
                setTimeStatus(true)
            }
            else {
                setTimer()
                setTimer(false)
            }
            // console.log(timer);


            // Total Amount referral bonus  by user till date
            let ReferEarned = await oracleInstance.referEarned(walletAddress)
            ReferEarned = ethers.utils.formatEther(ReferEarned)

            // claim  amount remaining from total amount of bonus 
            let referamount = await oracleInstance.referAmount(walletAddress)
            referamount = ethers.utils.formatEther(referamount)

            //Claim Amount 
            const referclaimd = Number(ReferEarned) - Number(referamount)

            setReferralclaimed(referclaimd)


            // console.log(referclaimd,"claim");

            try {
                let referData = await oracleInstance.referrer(walletAddress)

                // total bonus amount 
                let totalReferred = referData.totalReferred
                totalReferred = ethers.utils.formatEther(totalReferred)
                setTotalReferredAmount(totalReferred)
                // console.log(totalReferred);

                // count 
                let totalCodeGenerated = referData.referCount
                totalCodeGenerated = parseInt(totalCodeGenerated)
                setNoOfRefrral(totalCodeGenerated)

                for (let i = 1; i <= totalCodeGenerated; i++) {

                    let referCodeData = await oracleInstance.referralData(walletAddress, i)

                    let referAmount = ethers.utils.formatEther(referCodeData.referAmount)

                    array.push({ referUser: referCodeData[0], referAmount: referAmount })
                }


                setreferData(array)

            }

            catch (Err) {
                console.warn("err", Err);

            }
        } else {
            setreferData()
            setNoOfRefrral()
            setReferralclaimed()
            setTotalReferredAmount()
            setTimer()
            setTimeStatus(false)


        }
    }

    // console.log(Timer);

    // Referral Claim Function 
    async function ClaimReferral() {
        const signer = window.ethereum ? (new ethers.providers.Web3Provider(window.ethereum)).getSigner() : new ethers.providers.JsonRpcProvider(rpcUrl)

        oracleInstance = new ethers.Contract(oracleAddr, saleAbi, signer)

        try {

            const test = await oracleInstance.claimReferral()
            toast("Transaction is inProgress")
            await test.wait()
            toast.success("Transaction done")
            referralGeneration()
        } catch (e) {
            toast.error(e.reason ? e.reason : (e.data ? (e.data.message) : e.message))
        }

    }


    // useEffect(() => {
    //     referralGeneration()
    // }, [])

    useEffect(() => {
        referralGeneration()

       
    }, [walletAddress])






    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <section className='borderboxmain'>
                <img className='linetopleft' src={linetoprightbox} alt="border line" />
                <img className='linebottomright' src={linebottomrightbox} alt="border line" />
            </section>

            <section className="refDetails">
                <div className="oc_container">
                    <div className="title">
                        <h2>Referral Bonus</h2>
                    </div>
                    <div className="projects">
                        <div className="oc_container">
                            {/* List Box */}
                            <div className="row mb-5">
                                <div className="col-md-12 col-lg-12">
                                    <div className="row countBox">
                                        <div className="col-sm-4">
                                            <div className="countinbox">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><script xmlns="" />
                                                    <path fill="var(--ci-primary-color, currentColor)" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" class="ci-primary" />
                                                </svg>
                                                <p>Referral claimed</p>
                                                <h3>{localeFixed(referralclaimed) || 0}</h3>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="countinbox">
                                                <svg baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" overflow="visible" className="sc-5f2a7416-0 jbewcx"><g fill="currentColor"><path d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path fill-opacity="0.302" d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path fill-opacity="0.302" d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path fill-opacity="0.302" d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path fill-opacity="0.302" d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path fill-opacity="0.302" d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path><path fill-opacity="0.302" d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></g></svg>

                                                <p>No. of referrals</p>
                                                <h3>{noOfRefrral || 0}</h3>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="countinbox">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><script xmlns="" />
                                                    <path fill="var(--ci-primary-color, currentColor)" d="M296,240H216a46.222,46.222,0,1,1,0-92.444H344v-32H276V56H244v59.556H216A78.222,78.222,0,0,0,216,272h80a46.274,46.274,0,0,1,46.222,46.222v3.556A46.274,46.274,0,0,1,296,368H160.593v32H244v56h32V400h20a78.31,78.31,0,0,0,78.222-78.222v-3.556A78.31,78.31,0,0,0,296,240Z" class="ci-primary" />
                                                </svg>
                                                <p>Total Referral Bonus </p>
                                                <h3>{localeFixed(totalReferredAmount) || 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="refDetailsList">
                        <div className='table-responsive'>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Sr.no</th>
                                        <th>Wallet Address</th>
                                        <th>Bonus Amount (OC)</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {referData ? referData.map((pack, i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <Tooltip content={pack.referUser} customCss={css` white-space: nowrap;`}>
                                                    <span>{pack.referUser.substring(0, 4)} ... {pack.referUser.slice(-4)}</span>
                                                </Tooltip>
                                            </td>

                                            <td>{localeFixed(pack.referAmount)}</td>
                                            {/* <td>50,000</td> */}
                                        </tr>
                                    )) : <></>}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </section>
            <section className="claimBonus">
                <div className="oc_container">
                    <div className="buttonGrp">
                        <button className="oc_btn" onClick={ClaimReferral} >Claim Bonus</button>
                        <br />
                        {TimeStatus ? <p>Next Claim after <Countdown date={Timer} /></p> : false}

                        <br />
                        <br />
                        {/* new Date(new Date().setDate(new Date().getDate()-2)) */}
                        <p>You can claim after 27 April 09:00 PM (GMT)</p>
                        {/* //aur 5 baar claim hoga.. at every 1 month interval */}
                        <p>You will be claiming 20% of your referral earnings in an interval of 30 days, total 5 claims.</p>
                    </div>
                </div>
            </section>


        </>

    )
}

export default Ref