import React from 'react'
import linetoprightbox from '../../Assets/images/linetoprightbox.svg'
import linebottomrightbox from '../../Assets/images/linebottomrightbox.svg'
import Faqs from '../../Components/Faqs/Faqs'
import BalanceOverview from '../../Components/BalanceOverview/BalanceOverview'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Statisics from '../../Components/Statisics/Statisics'
import BuyOc from '../../Components/BuyOc/BuyOc'
import BuySell from '../../Components/ButSell/BuySell'
import { useLocation } from 'react-router'
const SaleDetails = () => {
   
    return (
        <>
            <section className='borderboxmain'>
                <img className='linetopleft' src={linetoprightbox} alt="border line" />
                <img className='linebottomright' src={linebottomrightbox} alt="border line" />
            </section>
            <PublicSale />
            <BuySell />
            <BuyOc />
            <Statisics />
            <BalanceOverview />
            <Faqs />
        </>
    )
}

export default SaleDetails